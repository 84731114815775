import { DialogWrapper } from "components/Layout20/DialogWrapper";
import ButtonSubmit from "components/MyComponents/ButtonSubmit";
import { Field, Form, Formik, FormikProps } from "formik";
import { YUP_VALIDATION } from "helpers/YupHelper";
import { is_in_array } from "helpers/predicate_helper";
import CorpoItemOption from "pages/Authentication/register/CorpoItemOption";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { DEAL_ADD_ACT } from "./store/actionsDealAdd";
import { useMemo } from "react";
import { translateOptions } from "helpers/text_helper";
import CustomSelect, { IOption, SELECT_SINGLE_DEFAULT_PLACEHOLDER } from "components/MyComponents/CustomSelect";

interface FormValues {
  appIds: string[];
  bagTypeId: string;
  suggestedVehicleTypeId: string;
}

const addonBagsDomain: IOption[] = [
  {
    "value": "SMO",
    "label": "Registration.AdOns.Bag.SendMe",
    "labelKey": "Registration.AdOns.Bag.SendMe",
  },
  {
    "value": "AHO",
    "label": "Registration.AdOns.Bag.AlreadyHave",
    "labelKey": "Registration.AdOns.Bag.AlreadyHave",
  },
  {
    "value": "BIO",
    "label": "Registration.AdOns.Bag.BuyInOffice",
    "labelKey": "Registration.AdOns.Bag.BuyInOffice",
  }
]


const vehicleDomainCourier: IOption[] = [
  {
    "value": "O_BIKE",
    "label": "Registration.VehicleType.OwnBike",
    "labelKey": "Registration.VehicleType.OwnBike",
  },
  {
    "value": "O_SCOOT",
    "label": "Registration.VehicleType.OwnScoot",
    "labelKey": "Registration.VehicleType.OwnScoot",
  },
  {
    "value": "O_CAR",
    "label": "Registration.VehicleType.OwnCar",
    "labelKey": "Registration.VehicleType.OwnCar",
  },
  {
    "value": "R_BIKE",
    "label": "Registration.VehicleType.RentMeBike",
    "labelKey": "Registration.VehicleType.RentMeBike",
  },
  {
    "value": "R_SCOOT",
    "label": "Registration.VehicleType.RentMeScoot",
    "labelKey": "Registration.VehicleType.RentMeScoot",
  },
  {
    "value": "R_CAR",
    "label": "Registration.VehicleType.RentMeCar",
    "labelKey": "Registration.VehicleType.RentMeCar",
  }
];


const vehicleDomainTaxi: IOption[] = [
  {
    "value": "O_CAR",
    "label": "Registration.VehicleType.OwnCar",
    "labelKey": "Registration.VehicleType.OwnCar",
  },
  {
    "value": "R_CAR",
    "label": "Registration.VehicleType.RentMeCar",
    "labelKey": "Registration.VehicleType.RentMeCar",
  }
]


const DealAddPage = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const contractorId = useSelector((state: any) => state.LoginState.contractorId);
  const suggestedApplications = useSelector((state: any) => state.LoginState.suggestedApplications);
  const companyId = useSelector((state: any) => state.LoginState.companyId);
  const INITIAL_VALUES: FormValues = {suggestedVehicleTypeId:'', bagTypeId: '', appIds: suggestedApplications?.length === 1 ? [suggestedApplications[0].value] : [] };
  const addonBagsDomainTr = useMemo(() => translateOptions(addonBagsDomain, t), [addonBagsDomain, i18n.language]);
  const isLoading = useSelector((state: any) => state.DalAddStore.isLoading);
  const isCourier = companyId !== 'AC' && companyId !== 'AT' ;
  const vehicleDomain = useMemo(() => translateOptions(isCourier? vehicleDomainCourier: vehicleDomainTaxi, t), [vehicleDomainCourier , vehicleDomainTaxi, isCourier, i18n.language]);

  const PROFILE_REMOVAL_VALIDATOR = YUP_VALIDATION.YUP_OBJ.shape({
    bagTypeId: isCourier ? YUP_VALIDATION.YUP_STRING_REQ_SELECT : YUP_VALIDATION.YUP_STRING,
    appIds: YUP_VALIDATION.YUP_STRING_REQ_MULTISELECT,
  });

  const onSubmit = (values: FormValues) => {
    dispatch(DEAL_ADD_ACT.dealAdd({ ...values, contractorId }, history));
  };

  const renderForm = (formikBag: FormikProps<FormValues>): JSX.Element => {

    const toggleSelection = (appId: string) => {

      if (suggestedApplications.length === 1) {
        return;
      }

      const wasSelected = is_in_array(formikBag.values.appIds, appId);
      if (wasSelected) {
        const appsFiltered = formikBag.values.appIds.filter(app => app !== appId);
        formikBag.setFieldValue('appIds', appsFiltered);
      } else {
        const appsExtended = [...formikBag.values.appIds, appId]
        formikBag.setFieldValue('appIds', appsExtended);
      }
    }
    return (
      <Form>
        <Row className="pb-1 mt-3 deal-add">
          <Col className="reg-app-item-grid pb-2">
            {suggestedApplications.map((item: { value: string, label: string, extraCss: string }, key: number) => (
              <CorpoItemOption
                appId={item.value}
                label={item.label}
                extraCss={item.extraCss}
                isSelected={is_in_array(formikBag.values.appIds, item.value)}
                key={'app_reg_item_' + key}
                toggleSelection={toggleSelection}

              />
            ))
            }
          </Col>
        </Row>

        {isCourier &&
          <Field name="bagTypeId" label="Registration.Page5.bagTypeId"
            textAfter="Registration.Page5.bagTypeId.AdditionalComment"
            placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
            options={addonBagsDomainTr} className="custom-select" component={CustomSelect}
            isDisabled={isLoading}
          />
        }

        <Field name="suggestedVehicleTypeId" label="Registration.Page5.Vehicle"
          textAfter="Registration.Page5.Vehicle.AdditionalComment"
          placeholder={SELECT_SINGLE_DEFAULT_PLACEHOLDER} isMulti={false}
          options={vehicleDomain} className="custom-select" component={CustomSelect} />

        <Row className="py-3">
          <Col>
            <ButtonSubmit id="button-deal-add-new"
              disabled={formikBag.values.appIds.length === 0}
              isLoading={isLoading} label="dealAdd.submit" iconClass="fa-regular fa-circle-plus fa-lg" />
          </Col>
        </Row>
      </Form>
    );
  }

  return (
    <Container>
      <DialogWrapper title="dealAdd.header">
        <Formik
          initialValues={INITIAL_VALUES}
          validationSchema={PROFILE_REMOVAL_VALIDATOR}
          render={renderForm}
          onSubmit={onSubmit}
        />
      </DialogWrapper></Container>
  );




};

export default DealAddPage; 