import Footer20Unauth from "components/Layout20/Footer20Unauth"
import { getCompanyContext } from "helpers/companyContextHelper"
import { IMG_UTIL } from "helpers/image_helper"
import { ReactElement } from "react"
import Unauth20WrapperLanguageChangeMenu from "./Unauth20WrapperLanguageChangeMenu"

interface Unauth20WrapperProps {
    children: ReactElement
}

export const Unauth20Wrapper = ({ children }: Unauth20WrapperProps) => {

    const company = getCompanyContext();
    return (
        <>
            <section className="form-sec">
                <div className="container with-bg pt-xl-0">
                    <div className="main-logo desktop-hide">
                        <div className="logo-panel-mobile">
                            <img className="logo-mobile" src={IMG_UTIL.getCompanyLogIn(company).default} alt="Spirit 24" />
                            <Unauth20WrapperLanguageChangeMenu alignment="vertical" />
                        </div>
                    </div>
                    <div className="row items-center h-100">
                        <div className="col-md-6 h-100">
                            <div className="img-100 mobile-hide h-100">
                                <img src="/img/side-img.png" className="login-image" />                                
                            </div>
                        </div>
                        <div className="col-md-6 h-100 pt-1 pt-md-0" >
                            <div className="mobile-hide">
                                <Unauth20WrapperLanguageChangeMenu alignment="horizontal" />
                            </div>
                            <div className="unauth-panel">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <Footer20Unauth />
        </>
    )
}