import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Route, Redirect } from "react-router-dom"
import LoadingPage from "../components/Common/LoadingPage";
import { getAuthContextFromStorage } from "../helpers/auth_storage_helper";
import { reautorize } from "../store/actions";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isAccessGranted,
  reauthenticating,
  ...rest
}) => {

  const dispatch = useDispatch();
  if (reauthenticating) {
    return (<div id="not-logged-in"><LoadingPage /></div>)
  }

  if (isAuthProtected && !isAccessGranted) {
    const loggedInContext = getAuthContextFromStorage();
    if (loggedInContext && loggedInContext.email) {
      dispatch(reautorize(loggedInContext));
      return (<div id="not-logged-in"><LoadingPage /></div>)
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !isAccessGranted) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return (
          <><div id={isAccessGranted ? "logged-in" : "not-logged-in"} />
            <Layout>
              <Component {...props} />
            </Layout>
          </>
        )
      }}
    />
  )
}


Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  history: PropTypes.object,

}

const mapStatetoProps = state => {
  const { isAccessGranted, reauthenticating } = state.LoginState;
  return { isAccessGranted, reauthenticating};
};

export default connect(mapStatetoProps)(Authmiddleware);