import { getCompanyContextLong, getCompanyHrefByContext } from "helpers/companyContextHelper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";
import AppStoreButtons from "./AppStoreButtons";
const Footer20Unauth = () => {
    const companyByContext = getCompanyContextLong();
    const companyName = useSelector((state: any) => state.LoginState.companyName);
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <section className="bottom-sec">
            <div className="container fluid">
                <div className="row">
                    <div className="col">
                        <AppStoreButtons align="text-center" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 pt-1">
                        <div className="btn-links">
                            <a href="#" onClick={() => history.push(ROUTES.ROUTE_PRIVACY_POLICY)}><i className="fa-duotone fa-file-shield fa-lg px-1"></i>{t('footer.privacy_policy_and_cookies')}</a>
                            <a href="#" onClick={() => history.push(ROUTES.ROUTE_CONTACT_FORM_UNLOGGED)}><i className="fa-duotone fa-envelope fa-lg px-1"></i> {t('ContactForm.NAV')}</a>
                        </div>
                    </div>
                    <div className="col-md-6 pt-1">
                        <div className="btn-links right">
                            <a href={getCompanyHrefByContext()} target="_blank" rel="noreferrer">
                                <i className="fa-duotone fa-copyright fa-lg px-1"></i> {new Date().getFullYear()}&nbsp;{companyName ? companyName : companyByContext}
                            </a>
                            <a href="#"><i className="fa-duotone fa-screwdriver-wrench fa-lg px-1"></i>DX Soft</a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default Footer20Unauth
