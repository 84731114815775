import { useWindowSize } from "@react-hook/window-size";
import { getCompanyContextLong } from "helpers/companyContextHelper";
import { useState } from "react";
import { getUA, isAndroid, isBrowser, isIOS, isMobile, isMobileOnly } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { ROUTES } from "routes";
import AppStoreButtons from "./AppStoreButtons";

export const showStore = (deviceType: 'android' | 'iphone', width: number) => {
    return true || getUA.toLocaleLowerCase().includes(deviceType) || width > 1200;
}

const Footer20 = () => {
    const companyByContext = getCompanyContextLong();
    const companyName = useSelector((state: any) => state.LoginState.companyName);
    const companyId = useSelector((state: any) => state.LoginState.companyId);
    const contractorId = useSelector((state: any) => state.LoginState.contractorId);
    const email = useSelector((state: any) => state.LoginState.email);
    const history = useHistory();
    const { t } = useTranslation();
    const [width, height] = useWindowSize()
    const [showHiddenData, setShowHiddenData] = useState(false)

    return (
        <section className="bottom-sec">
            <Container fluid>
                {width <= 1200 &&
                    <div className="row pt-2">
                        <div className="col">
                            <AppStoreButtons align="text-center" />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-6 pt-1">
                        <div className="btn-links">
                            <a href="#" onClick={() => history.push(ROUTES.ROUTE_PRIVACY_POLICY)}><i className="fa-duotone fa-file-shield fa-lg px-1"></i>{t('footer.privacy_policy_and_cookies')}</a>
                            <a href="#" onClick={() => history.push(ROUTES.ROUTE_CONTACT_FORM)}><i className="fa-duotone fa-envelope fa-lg px-1"></i> {t('ContactForm.NAV')}</a>
                        </div>
                    </div>
                    <div className="col-md-6 pt-1">
                        <div className="btn-links right">
                            <a href={companyId != 'AC' ? "https://avalon-logistics.pl/" : "https://avalon-logistics.pl/kierowcy/"} target="_blank" rel="noreferrer">
                                <i className="fa-duotone fa-copyright fa-lg px-1"></i> {new Date().getFullYear()}&nbsp;{companyName ? companyName : companyByContext}
                            </a>
                            <a onClick={() => { setShowHiddenData(showHiddenData => !showHiddenData) }}><i className="fa-duotone fa-screwdriver-wrench fa-lg px-1"></i> DX Soft</a>
                        </div>
                    </div>
                </div>

                {
                    (showHiddenData &&
                        (
                            email === 'dominik.niewiadomy@gmail.com' ||
                            email.endsWith('@avalon-cars.pl') ||
                            email.endsWith('@quick-apps.pl') ||
                            email.endsWith('@avalon-logistics.pl')
                        )
                    )
                    &&
                    <div className="py-3">
                        <Row className="footer-info border-top blue">
                            <Col xs={12} md={6} className="text-center text-md-start"><i className="fa-solid fa-tag fa-lg px-1"></i> Spirit-SC-2.1.3-exclusions_b266</Col>
                            <Col xs={12} md={2} className="text-center text-md-end"><i className="fa-regular fa-address-card fa-lg px-1"></i> UID:  {contractorId} # {companyId}</Col>
                            <Col xs={12} md={4}>
                                <i className="fa-solid fa-display fa-lg px-1"></i>   w: {width}px, h:{height}px  type: {isMobileOnly ? 'X' : ''}{isBrowser ? 'B' : ''}{isMobile ? 'M' : ''}{isAndroid ? 'A' : ''}{isIOS ? 'I' : ''}
                            </Col>
                            <Col xs="auto w-100 border-top blue" >
                                {getUA}
                            </Col>
                        </Row>
                    </div>
                }
                <div className="row bottom-sec-hidden" id="SPIRIT24-ID">{contractorId}</div>
            </Container>
        </section>
    )
}



export default Footer20