import React from "react";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
//i18n
import { USER_ROLES } from "constants/UserRoles";
import { useTranslation } from "react-i18next";
import { ROUTES } from "routes";
import MenuItem from "./MenuItem";
import { getLogoImage20, getLogoImage20Width } from "./Images20Helper";
import MenuItemDynamicSuggestedApps from "./MenuItemDynamicSuggestedApps";

const Sidebar20 = () => {
  const { t } = useTranslation();
  const { availableRoles, companyId, suggestedApplications, extDocsToSignCount } = useSelector((state) => {
    return (
      {
        availableRoles: state.LoginState.availableRoles,
        companyId: state.LoginState.companyId,
        extDocsToSignCount: state.LoginState.extDocsToSignCount,
        suggestedApplications: state.LoginState.suggestedApplications
      }
    )
  });

console.log('extDocsToSignCount',extDocsToSignCount)

  return (
    <React.Fragment>
      <aside>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="logo">
                  <img src={getLogoImage20(companyId, true)} width={getLogoImage20Width(companyId, true)} />
                </div>
                <div className="sidemenu">
                  <ul className="sidemenu-styles">
                    <MenuItem labelKey="Dashboard.NAV" imageSrc="/img/menu-home.svg" routeTo={ROUTES.ROUTE_DASHBOARD} />

                    {suggestedApplications?.length >= 0 &&
                      <MenuItemDynamicSuggestedApps />
                    }

                    {extDocsToSignCount > 0 && 
                      <MenuItem labelKey="ExtDocs.NAV.List" imageSrc="/img/menu-exd-doc-sign.svg" routeTo={ROUTES.ROUTE_EXT_DOC_TO_SIGN}/>
                    }

                    <MenuItem labelKey="Profile.NAV" imageSrc="/img/menu-user.svg" routeTo={ROUTES.ROUTE_PROFILE} roleRequired={USER_ROLES.PROFILE} />
                    <MenuItem labelKey="Agreement.List.NAV" imageSrc="/img/menu-file.svg" routeTo={ROUTES.ROUTE_AGREEMENT_LIST} roleRequired={USER_ROLES.AGREEMENT_LIST} />
                    <MenuItem labelKey="Attachment.List.NAV" imageSrc="/img/menu-document.svg" routeTo={ROUTES.ROUTE_ATTACHMENT_LIST} roleRequired={USER_ROLES.ATTACHMENT_LIST} />
                    <MenuItem labelKey="Settlements.NAV.List" imageSrc="/img/menu-hand-money.svg" routeTo={ROUTES.ROUTE_SETTLEMENT_LIST} roleRequired={USER_ROLES.SETTLEMENT_LIST} />
                    <MenuItem labelKey="Invoices.NAV.List" imageSrc="/img/menu-file-send.svg" routeTo={ROUTES.ROUTE_INVOICE_LIST} roleRequired={USER_ROLES.INVOICE_ADD} />
                    <MenuItem labelKey="ContactForm.NAV" imageSrc="/img/menu-question.svg" routeTo={ROUTES.ROUTE_CONTACT_FORM} />
                  </ul>
                </div>
                {availableRoles.includes(USER_ROLES.AFF_LINK_SEND) &&
                  <div className="side-btm">
                    <div className="btm-title">
                      <h3>{t("AffLinkSend.NAV")}</h3>
                      <div className="btm-btn">
                        <Link to={ROUTES.ROUTE_AFF_LINK_SEND}>                     
                        <i className="fa-regular fa-circle-dollar fa-lg  fa-beat-fade pe-1"></i>
                        {t("AffLinkSend.NAV.SecondLine")} 
                        </Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
      </aside>
    </React.Fragment>
  );
};


export default withRouter(Sidebar20);
